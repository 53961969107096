export const isSixtyDaysRemaining = (endDateStr: string) => {
  if (endDateStr === '') {
    return false;
  }

  const endDate = new Date(endDateStr);
  const today = new Date();
  const diffTime = endDate.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 60;
};

export const isEvergreenDate = (endDateStr: string) => {
  if (endDateStr === '') {
    return false;
  }

  const endDate = new Date(endDateStr);
  return endDate.getFullYear() === 9999;
};

export const isProviderStartDateInFuture = (startDateStr) => {
  if (startDateStr === '') {
    return false;
  }
  const startDate = new Date(startDateStr);
  const curDate = new Date();
  return curDate <= startDate;
};

export const formatProviderStartDate = (startDateStr: string): string => {
  if (startDateStr === '') {
    return '';
  }
  const startDate = new Date(startDateStr);
  const year = startDate.getFullYear().toString().slice(-2);
  const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
  const day = startDate.getDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
};
